import React from 'react';

function Link({ text, to }) {
    return (
        <a href={to} target='_blank'>
            {text}
        </a>
    );
}

export default Link;
